import CryptoJS from "crypto-js";
export const DecryptED = (userid) => {
  if (userid.includes("enc-")) {
    var dec = userid.split("enc-")[1];
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_KEY);
    var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_IV);
    var decryptedWA = CryptoJS.AES.decrypt(atob(dec), key, { iv: iv });

    var decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);

    return decryptedUtf8;
  }
  return userid;
};
