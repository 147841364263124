import * as common from "../constants/CommonConstant";

const initialState = {
  loading: false,
  errors: "",
  schName: [],
  AcadYear :[],
  searchedStud: [],
  staffName: [],
};

const CommonReducers = (state = initialState, action) => {
  switch (action.type) {
    case common.SEARCH_STUD_START:
      return {
        ...state,
        loading: true,
      };
    case common.SEARCH_STUD_SUCCESS:
      return {
        ...state,
        searchedStud: action.payload,
        loading: false,
      };
    case common.SEARCH_STUD_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case common.SEARCH_STUD_RESET:
      return {
        ...state,
        searchedStud: [],
        errors: "",
      };

    case common.GET_STAFF_SUCCESS:
      return {
        ...state,
        staffName: action.payload,
      };

    case common.GET_STAFF_RESET:
      return {
        ...state,
        staffName: [],
      };

    case common.GET_SCHOOL_START:
      return {
        ...state,
        loading: true,
        errors: "",
      };
    case common.GET_SCHOOL_SUCCESS:
      return {
        ...state,
        schName: action.payload,
        loading: false,
        errors: "",
      };
      case common.GET_ACAD_SUCCESS:
        return {
          ...state,
          AcadYear: action.payload,
        };
    default:
      return state;
  }
};

export default CommonReducers;
