import React from "react";
import { Box, Spinner } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Box
      display={"flex"}
      position={'relative'}
      w={'full'}
      h={'100vh'}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Spinner color="#5a67d8" size="lg" />
    </Box>
  );
};

export default Loader;
