import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import combineReducers from "./combineReducer";
import rootSaga from "./saga/rootSaga";
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = configureStore({
  reducer: combineReducers,
  middleware: [...middlewares],
});

sagaMiddleware.run(rootSaga);

export default store;
