import * as Assignment from "../constants/CommonConstant";

const initialState = {
  loading: false,
  errors: "",
  Data: [],
};

const AssignmentReducers = (state = initialState, action) => {
  switch (action.type) {
    case Assignment.GET_ASSIGNMENT_START:
      return {
        ...state,
        loading: true,
      };

    case Assignment.GET_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        Data: action.payload,
        loading: false,
      };

    case Assignment.GET_ASSIGNMENT_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default AssignmentReducers;
