import {
  GET_EXAM_START,
  GET_EXAM_SUCCESS,
  GET_EXAM_ERROR,
  GET_EXAM_RESET,
} from "../constants/CommonConstant";

export const getExamStart = (payload) => {
  console.log(payload)
  return {
    type: GET_EXAM_START,
    payload,
  };
};
export const getExamSuccess = (payload) => {
  return {
    type: GET_EXAM_SUCCESS,
    payload,
  };
};
export const getExamError = (payload) => {
  return {
    type: GET_EXAM_ERROR,
    payload,
  };
};
export const getExamReset = (payload) => {
  return {
    type: GET_EXAM_RESET,
  };
};
