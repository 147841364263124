import * as examModule from "../constants/CommonConstant";

const initialState = {
  loading: false,
  errors: "",
  Data: [],
};

const examModuleReducers = (state = initialState, action) => {
  switch (action.type) {
    case examModule.GET_EXAM_START:
      return {
        ...state,
        loading: true,
      };

    case examModule.GET_EXAM_SUCCESS:
      return {
        ...state,
        Data: action.payload,
        loading: false,
      };

    case examModule.GET_EXAM_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default examModuleReducers;
