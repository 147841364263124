export const SEARCH_STUD_START = "SEARCH_STUD_START";
export const SEARCH_STUD_SUCCESS = "SEARCH_STUD_SUCCESS";
export const SEARCH_STUD_ERROR = "SEARCH_STUD_ERROR";
export const SEARCH_STUD_RESET = "SEARCH_STUD_RESET";

export const GET_SCHOOL_START = "GET_SCHOOL_START";
export const GET_SCHOOL_SUCCESS = "GET_SCHOOL_SUCCESS";
export const GET_SCHOOL_ERROR = "GET_SCHOOL_ERROR";
export const GET_SCHOOL_RESET = "GET_SCHOOL_RESET";

// Exam Module App

export const GET_EXAM_START = "GET_EXAM_START";
export const GET_EXAM_SUCCESS = "GET_EXAM_SUCCESS";
export const GET_EXAM_ERROR = "GET_SEXAM_ERROR";
export const GET_EXAM_RESET = "GET_EXAM_RESET";

// Assignement Module App

export const GET_ASSIGNMENT_START = "GET_ASSIGNMENT_START";
export const GET_ASSIGNMENT_SUCCESS = "GET_ASSIGNMENT_SUCCESS";
export const GET_ASSIGNMENT_ERROR = "GET_ASSIGNMENT_ERROR";
export const GET_ASSIGNMENT_RESET = "GET_ASSIGNMENT_RESET";

//Get Teacher name

export const GET_STAFF_START = "GET_STAFF_START";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";
export const GET_STAFF_ERROR = "GET_STAFF_ERROR";
export const GET_STAFF_RESET = "GET_STAFF_RESET";

//Get Acad Year

export const GET_ACAD_START = "GET_ACAD_START";
export const GET_ACAD_SUCCESS = "GET_ACAD_SUCCESS";
export const GET_ACAD_ERROR = "GET_ACAD_ERROR";
export const GET_ACAD_RESET = "GET_ACAD_RESET";
