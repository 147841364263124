
import {
    Box,
    Text,
    Button,
    SimpleGrid,
    Image,
    Divider,
  } from "@chakra-ui/react";
  
const ReusableComponents = ({ Mtitle, title, step, avatar }) => {
    return (
      <>
        <SimpleGrid columns={{md:'2', sm:'1'}} padding={{ base: '1', sm: '1' }} maxW={900} w={"100%"} margin={"0 auto"}>
          <Box display={"flex"}  justifyContent={title ? "center" : "start"}>
            <Button
              w={35}
              h={35}
              backgroundColor={step === 1 ? "cyan.600" : "green.600"}
              color={"white"}
              disabled
              fontSize={17}
              borderRadius={"50%"}
            >
              {step}
            </Button>
            <Box marginLeft={4}>
              {" "}
              <Text
                fontSize={20}
                fontFamily={"sans-serif"}
                fontWeight={"bold"}
                mt={1}
              >
                {Mtitle}
              </Text>
              <Text mt={4} fontSize={14}>
                {title ? title : ""}
              </Text>
            </Box>
          </Box>
          <Box boxSize={"sm"}>
            <Image objectFit={"cover"} src={avatar} />
            <Divider />
          </Box>
        </SimpleGrid>
      </>
    );
  };

  export default ReusableComponents;