import React from "react";
import {  Heading, Text } from "@chakra-ui/react";

const NotFound = () => {
  return (
    <div
      className=""
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height:"100vh"
      }}
    >
      <Heading fontFamily={'monospace'} fontSize={'6xl'}>Oops!</Heading>
      <Text mt={3} fontSize={'2xl'}>404 - Page Not Found</Text>
    </div>
  );
};

export default NotFound;
