import axios from "axios";
import { token, live_Url } from "../../../config";

export const searchStudApi = async (data) => {
  return await axios.post(`${live_Url}/api/idcard/searchby/name`, data, {
    headers: { Authorization: token },
  });
};

// Get Teacher Name

export const getStaffNameAPI = async (data) => {
  return await axios.post(`${live_Url}/api/admin/lessonPlan/teachers`, data, {
    headers: { Authorization: token },
  });
};

export const getSchoolName = async (data) => {
  return await axios.post(
    `https://topschool.prisms.in/rest/index.php/user_list.json?`,
    { fun_name: "GetSchoolUrl", sid: data }
  );
};

export const getAcademicYearApi = async (data) => {
  return await axios.post(`${live_Url}/api/tscommon/getCurrentAcad`, data, {
    headers: { Authorization: token },
  });
};

export const getExamReport = async (data) => {
  return await axios.post(
    `https://topschool.prisms.in/rest/index.php/user_list.json?`,
    data
  );
};

// Assignment Module
export const getAssignmentApi = async (data) => {
  return await axios.post(
    `https://topschool.prisms.in/rest/index.php/staff_list.json`,
    data
  );
};
