import * as common from "../constants/CommonConstant";

export const searchStudentStart = (payload) => {
  return {
    type: common.SEARCH_STUD_START,
    payload,
  };
};

export const searchStudentSuccess = (payload) => {
  return {
    type: common.SEARCH_STUD_SUCCESS,
    payload,
  };
};

export const searchStudentError = (payload) => {
  return {
    type: common.SEARCH_STUD_ERROR,
    payload,
  };
};

export const searchStudentReset = () => {
  return {
    type: common.SEARCH_STUD_RESET,
  };
};

// SCHOOL NAME

export const getSchoolNameStart = (payload) => {
  console.log(payload);
  return {
    type: common.GET_SCHOOL_START,
    payload,
  };
};

export const getSchoolNameSuccess = (payload) => {
  return {
    type: common.GET_SCHOOL_SUCCESS,
    payload,
  };
};

export const getSchoolNameError = (payload) => {
  return {
    type: common.GET_SCHOOL_ERROR,
    payload,
  };
};

// Get Staff name

export const getStaffNameStart = (payload) => {
  return {
    type: common.GET_STAFF_START,
    payload,
  };
};

export const getStaffNameSuccess = (payload) => {
  return {
    type: common.GET_STAFF_SUCCESS,
    payload,
  };
};

export const getStaffNameError = (payload) => {
  return {
    type: common.GET_STAFF_ERROR,
    payload,
  };
};

export const getStaffNameReset = () => {
  return {
    type: common.GET_STAFF_RESET,
  };
};

// Acad Year

export const getAcadYearStart = (payload) => {
  return {
    type: common.GET_ACAD_START,
    payload,
  };
};

export const getAcadYearSuccess = (payload) => {
  return {
    type: common.GET_ACAD_SUCCESS,
    payload,
  };
};

export const getAcadYearError = (payload) => {
  return {
    type: common.GET_ACAD_ERROR,
    payload,
  };
};
