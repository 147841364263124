import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Routes";
import { ChakraProvider } from "@chakra-ui/react";
import store from "./redux/store";
import { Provider } from "react-redux";
import theme from "./theme";
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </Provider>
);
