import { takeLatest, put, delay, fork, call } from "redux-saga/effects";
import {
  GET_SCHOOL_START,
  SEARCH_STUD_START,
  GET_EXAM_START,
  GET_ASSIGNMENT_START,
  GET_STAFF_START,
  GET_ACAD_START,
} from "../../constants/CommonConstant";
import {
  searchStudentSuccess,
  searchStudentError,
  getSchoolNameSuccess,
  getSchoolNameError,
  getStaffNameSuccess,
  getStaffNameError,
  getAcadYearStart,
  getAcadYearSuccess,
  getAcadYearError,
} from "../../actions/common.action";

import { getExamSuccess, getExamError } from "../../actions/examModule.action";
import {
  getAssignmentSuccess,
  getAssignmentError,
} from "../../actions/Assignment.action";
import {
  searchStudApi,
  getSchoolName,
  getExamReport,
  getAssignmentApi,
  getStaffNameAPI,
  getAcademicYearApi,
} from "../api/CommonApi";

function* searchStudAsync(payload) {
  try {
    const response = yield call(searchStudApi, payload.payload);
    if (response.status === 200) {
      yield put(searchStudentSuccess(response.data));
    } else {
      yield put(searchStudentError(response.data));
    }
  } catch (error) {
    yield put(searchStudentError(error.response.data));
  }
}

function* searchStud() {
  yield takeLatest(SEARCH_STUD_START, searchStudAsync);
}

function* getSchoolNameAsync(payload) {
  try {
    const response = yield call(getSchoolName, payload.payload);
    if (response.status === 200) {
      delay(500);
      yield put(getSchoolNameSuccess(response.data));
    } else {
      yield put(getSchoolNameError(response.data));
    }
  } catch (error) {
    yield put(getSchoolNameError(error.response.data));
  }
}

function* getSchoolNameFunc() {
  yield takeLatest(GET_SCHOOL_START, getSchoolNameAsync);
}

// Get Exam Report
function* getExamReportFunc(payload) {
  try {
    const response = yield call(getExamReport, payload.payload);
    if (response.status === 200) {
      delay(500);
      yield put(getExamSuccess(response.data));
    } else {
      yield put(getExamError(response.data));
    }
  } catch (error) {
    yield put(getExamError(error.response.data));
  }
}

function* getExamReportAsync() {
  yield takeLatest(GET_EXAM_START, getExamReportFunc);
}

// GEt ASSIGNMENT FUNCTION

function* getAssignmentFunc(payload) {
  try {
    const response = yield call(getAssignmentApi, payload.payload);
    if (response.status === 200) {
      delay(500);
      yield put(getAssignmentSuccess(response.data));
    } else {
      yield put(getAssignmentError(response.data));
    }
  } catch (error) {
    yield put(getAssignmentError(error.response.data));
  }
}

function* getAssignment() {
  yield takeLatest(GET_ASSIGNMENT_START, getAssignmentFunc);
}

//Staff Name
function* getStaffNameAsync(payload) {
  try {
    const response = yield call(getStaffNameAPI, payload.payload);
    if (response.status === 200) {
      delay(200);
      yield put(getAcadYearSuccess(response.data));
    } else {
      yield put(getAcadYearError(response.data));
    }
  } catch (error) {
    yield put(getAcadYearError(error.response.data));
  }
}

function* getStaffFunc() {
  yield takeLatest(GET_STAFF_START, getStaffNameAsync);
}

// ACAD YEAR API :
function* getAcadAsync(payload) {
  try {
    const response = yield call(getAcademicYearApi, payload.payload);
    if (response.status === 200) {
      delay(500);
      yield put(getAcadYearSuccess(response.data));
    } else {
      yield put(getAcadYearError(response.data));
    }
  } catch (error) {
    yield put(getAcadYearError(error.response.data));
  }
}

function* getAcadFunc() {
  yield takeLatest(GET_ACAD_START, getAcadAsync);
}

const CommonApiSaga = [
  fork(searchStud),
  fork(getSchoolNameFunc),
  fork(getExamReportAsync),
  fork(getAssignment),
  fork(getStaffFunc),
  fork(getAcadFunc)
];

export default CommonApiSaga;
