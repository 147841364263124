import {
  GET_ASSIGNMENT_START,
  GET_ASSIGNMENT_SUCCESS,
  GET_ASSIGNMENT_ERROR,
  GET_ASSIGNMENT_RESET,
} from "../constants/CommonConstant";

export const getAssignmentStart = (payload) => {
  return {
    type: GET_ASSIGNMENT_START,
    payload,
  };
};
export const getAssignmentSuccess = (payload) => {
  return {
    type: GET_ASSIGNMENT_SUCCESS,
    payload,
  };
};
export const getAssignmentError = (payload) => {
  return {
    type: GET_ASSIGNMENT_ERROR,
    payload,
  };
};
export const getAssignmentReset = (payload) => {
  return {
    type: GET_ASSIGNMENT_RESET,
  };
};
