export const local_Url = "http://localhost:8080"
// export const local_Url = "http://192.168.1.5:8080"

// http://192.168.1.5:8080;
export const live_Url = "https://marcopolo.prisms.in";
export const token = "#$laksdfnlknoea#$@$%^&%$";


// ICONS
export const notificationIcon =
  "https://commonfiles.prisms.in/commonfiles/notifications.png";
export const assignmentsIcon =
  "https://commonfiles.prisms.in/commonfiles/1454938655_assignments.png";
export const notesIcon =
  "https://commonfiles.prisms.in/commonfiles/1480334325_1479877505_Online-application-icon.png";
