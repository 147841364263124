import { combineReducers } from "redux";
import CommonReducers from "./reducers/common.reducers";
import examModuleReducers from "./reducers/ExamModule";
import AssignmentReducers from "./reducers/Assignment";

export default combineReducers({
  searchStudent: CommonReducers,
  examModule: examModuleReducers,
  assignment: AssignmentReducers,
});
