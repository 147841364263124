import React from "react";
import { Box } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import "../App.css";
const Dashboard = () => {
  return (
    <Box>
      <Heading
        as="h2"
        size="xl"
        textAlign={"center"}
        color={"#2D3748"}
        fontSize="50px"
        fontFamily={"Poppins"}
        mt={200}
        textDecoration={"underline"}
      >
        Welcome To Mpages App
      </Heading>
    </Box>
  );
};

export default Dashboard;
